// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-e-consent-js": () => import("./../src/pages/e-consent.js" /* webpackChunkName: "component---src-pages-e-consent-js" */),
  "component---src-pages-get-started-js": () => import("./../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../src/pages/legal-disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-policies-js": () => import("./../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rates-and-fees-js": () => import("./../src/pages/rates-and-fees.js" /* webpackChunkName: "component---src-pages-rates-and-fees-js" */),
  "component---src-pages-responsible-lending-js": () => import("./../src/pages/responsible-lending.js" /* webpackChunkName: "component---src-pages-responsible-lending-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

